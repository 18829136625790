<template>
  <div class="auth-wrapper auth-v1">
    <v-btn
      color="secondary"
      @click="onFinishedPayment"
    >
      Payment successful
    </v-btn>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import { ref, computed, onMounted } from '@vue/composition-api'
import { useRouter } from '@core/utils'

export default {
  setup() {
    const { route, router } = useRouter()

    const saveCard = ref(null)
    const patmentIntent = ref(null)
    const computedMatchCode = computed(() => JSON.parse(localStorage.getItem('matchCode')))

    const onFinishedPayment = () => {
      router.push({ path: `/game/${computedMatchCode.value}` })
    }

    onMounted(() => {
      const { params, query } = route.value
      saveCard.value = params.saveCard
      patmentIntent.value = query.payment_intent
    })

    return {
      patmentIntent,

      computedMatchCode,

      onFinishedPayment,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/view.scss'
</style>
<style lang="scss" scoped>
  .white-border {
    border: 2px solid #FFFFFF !important;
  }

  .app-content-container {
    padding: 0px !important;
  }

  .card-img {
    height: auto;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .card-img {
    width: auto;
    height: 42px;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .card-img {
    width: auto;
    height: 42px;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .card-img {
    width: auto;
    height: 42px;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .card-img {
    width: auto;
    height: 42px;
  }
}
</style>
